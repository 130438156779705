<template>
  <div class="why-harvest">
    <div class="container is-max-widescreen">
      <p class="why-harvest-title mb-5">{{ $t('harvest2.title') }}</p>

      <div class="columns is-tablet">
        <div class="column is-12">
          <div class="card">
            <div class="card-content">
              <div class="harvest-img">
                <img src="/assets/img/harvest/harvest-3.png" alt="">
              </div>
              <div>
                <p class="harvest-card-title">{{ $t('harvest2.1_title') }}</p>
                <p>{{ $t('harvest2.1_desc') }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-12">
          <div class="card">
            <div class="card-content">
              <div class="harvest-img">
                <img src="/assets/img/harvest/harvest-5.png" alt="">
              </div>
              <div>
                <p class="harvest-card-title">{{ $t('harvest2.2_title') }}</p>
                <p>{{ $t('harvest2.2_desc') }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-12">
          <div class="card">
            <div class="card-content">
              <div class="harvest-img">
                <img src="/assets/img/harvest/harvest-6.png" alt="">
              </div>
              <div>
                <p class="harvest-card-title">{{ $t('harvest2.3_title') }}</p>
                <p>{{ $t('harvest2.3_desc') }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-12">
          <div class="card">
            <div class="card-content">
              <div class="harvest-img">
                <img src="/assets/img/supply/supply-3.png" alt="">
              </div>
              <div>
                <p class="harvest-card-title">{{ $t('harvest2.4_title') }}</p>
                <p>{{ $t('harvest2.4_desc') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "WhyHarvestWithSgara",
  data() {
    return {}
  },
}
</script>

<style lang="scss">
.why-harvest-title {
  color: #363636;
  font-size: 44px;
  font-weight: bold;
  text-align: center;
  padding-bottom: 1rem;
  @media (max-width: 768px) {
    font-size: 36px;
  }
}

.why-harvest {
  padding-bottom: 5rem;
  padding-top: 2rem;

  @media (max-width: 768px) {
    padding-top: 1rem;
  }

  .card {
    border-radius: unset;
    box-shadow: unset;
    background: unset;

    .card-content {
      background-color: #FFFFFF;
      border-radius: 24px;
      box-shadow: 0 5px 20px 0 rgb(0 0 0 / 5%);
      width: 100%;
      height: 100%;
      display: flex;
      font-size: 24px;
      @media (max-width: 768px) {
        font-size: 20px;
      }
    }
  }

  .harvest-img {
    background-color: #128FE3;
    display: flex;
    min-width: 100px;
    width: 100px;
    height: 100px;
    justify-content: center;
    align-items: center;
    border-radius: 200px;
    margin-right: 2rem;

    @media (max-width: 768px) {
      margin-right: 1.5rem;
    }

    > img {
      height: 60px;
    }
  }

  .harvest-card-title {
    color: #128FE3;
    font-size: 28px;
    font-weight: bold;
    margin-bottom: .25rem;
    @media (max-width: 768px) {
      font-size: 24px;

    }
  }

}
</style>
